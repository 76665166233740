<template>
  <div id="mian">
    <div class="header">
      <span id="fzcolor">基本信息</span>
      <span
        @click="
          toUrl('add_agentlist_js', { id: $route.query.id, pageType: 'edit' })
        "
        >结算信息</span
      >
      <span
        @click="
          toUrl('add_agentlist_cp', { id: $route.query.id, pageType: 'edit' })
        "
        >产品信息</span
      >
    </div>
    <div v-auth="'ACCOUNT:AGENT:AGENTS:BASE/JBXX'">
        <div class="desc">
          <h5 class="jsxx">基本信息</h5>
          <div>
            <el-button
              v-auth="'ACCOUNT:AGENT:AGENTS:BASE/JBXX/EDIT'"
              @click="
                toUrl('add_agentlist_xx_edit', {
                  id: $route.query.id,
                  pageType: 'edit',
                })
              "
              class="editBt"
              type="primary"
              >编辑</el-button
            >
            <el-button v-auth="'ACCOUNT:AGENT:AGENTS:BASE/JBXX/RESENDMAIl'" class="reEmailBt" type="primary" @click="reEmail"
              >重发邮件</el-button
            >
          </div>
        </div>
        <div class="list clear">
          <ul class="listBox">
            <li>
              <span>代理商全称</span>
              <i class="hidden1">
                <el-tooltip
                  class="item"
                  effect="light"
                  :content="detail.agentName"
                  placement="top"
                >
                  <span>{{ detail.agentName }}</span>
                </el-tooltip>
              </i>
            </li>
            <li>
              <span>代理商编号</span>
              <i class="hidden1">{{ detail.agentId }}</i>
            </li>
            <li>
              <span>联系人</span>
              <i class="hidden1">{{ detail.contactName }}</i>
            </li>
            <li>
              <span>手机号码</span>
              <i class="hidden1">{{ detail.contactTel }}</i>
            </li>
            <li>
              <span>邮箱</span>
              <i class="hidden1">{{ detail.contactEmail }}</i>
            </li>
            <li>
              <span>联系地址</span>
              <i class="hidden1">
                <el-tooltip
                  class="item"
                  effect="light"
                  :content="detail.contactAddress"
                  placement="top"
                >
                  <span>{{ detail.contactAddress }}</span>
                </el-tooltip>
              </i>
            </li>
            <li>
              <span>法人姓名</span>
              <i class="hidden1">{{ detail.legalPersonName }}</i>
            </li>
            <li>
              <span>法人身份证号码</span>
              <i class="hidden1">{{ detail.legalPersonNo }}</i>
            </li>
            <li>
              <span>备注</span>
              <i class="hidden1">
                <el-tooltip
                  class="item"
                  effect="light"
                  :content="detail.remark"
                  placement="top"
                >
                  <span>{{ detail.remark }}</span>
                </el-tooltip>
              </i>
            </li>
            <li>
              <span>营业执照</span>
              <i class="hidden1">
                <a
                  v-if="detail.licencePicture"
                  href="javascript:;"
                  @click="showImg(detail.licencePicture)"
                  >点击预览</a
                >
              </i>
            </li>
            <li>
              <span>开户许可证</span>
              <i class="hidden1">
                <a
                  v-if="detail.accountOpeningLicense"
                  href="javascript:;"
                  @click="showImg(detail.accountOpeningLicense)"
                  >点击预览</a
                >
              </i>
            </li>
            <li>
              <span>身份证正面照</span>
              <i class="hidden1">
                <a
                  v-if="detail.idcardNormalPicture"
                  href="javascript:;"
                  @click="showImg(detail.idcardNormalPicture)"
                  >点击预览</a
                >
              </i>
            </li>
            <li>
              <span>代理商协议</span>
              <i class="hidden1">
                <a
                  v-if="detail.agentProtocol && detail.agentProtocol.length > 0"
                  href="javascript:;"
                  @click="agentProtocolBtn(detail.agentProtocol)"
                  >点击预览</a
                >
              </i>
            </li>
            <li>
              <span>身份证反面照</span>
              <i class="hidden1">
                <a
                  v-if="detail.idcardReversePicture"
                  href="javascript:;"
                  @click="showImg(detail.idcardReversePicture)"
                  >点击预览</a
                >
              </i>
            </li>
            <li>
              <span>创建时间</span>
              <i class="hidden1">{{ detail.createTime }}</i>
            </li>
            <li>
              <span>审核时间</span>
              <i class="hidden1">
                {{ detail.checkTime ? detail.checkTime : "" }}
              </i>
            </li>
            <li>
              <span>最后修改时间</span>
              <i class="hidden1">{{ detail.updateTime }}</i>
            </li>
            <li>
              <span>最后修改人</span>
              <i class="hidden1">{{ detail.lastUpdatedUser }}</i>
            </li>
          </ul>
        </div>
        <div class="status_desc">
          <span>审核状态</span>
          <span class="status">{{ checkStatus[detail.checkStatus] }}</span>
          <el-button
            v-auth="'ACCOUNT:AGENT:AGENTS:BASE/JBXX/PASS'"
            @click="audit('PASS')"
            v-if="detail.checkStatus != 'PASS'"
            :disabled="detail.checkStatus == 'PASS'"
            class="overBt active"
            type="primary"
            >通过</el-button
          >
          <el-button
            v-auth="'ACCOUNT:AGENT:AGENTS:BASE/JBXX/REJECT'"
            @click="audit('REJECT')"
            v-if="detail.checkStatus != 'PASS'"
            :disabled="
              detail.checkStatus == 'REJECT' || detail.checkStatus == 'PASS'
            "
            class="returnBt"
            type="primary"
            >驳回</el-button
          >
        </div>
        <div class="status_desc" style="margin-bottom: 200px">
          <span>商户状态</span>
          <span class="status">{{ activateStatus[detail.activateStatus] }}</span>
          <el-button
            v-auth="'ACCOUNT:AGENT:AGENTS:BASE/JBXX/ACTIVATE'"
            @click="setStatus('ACTIVATE')"
            v-if="detail.checkStatus == 'PASS'"
            class="returnBt"
            :class="detail.activateStatus == 'ACTIVATE' ? 'active' : ''"
            :disabled="detail.activateStatus == 'ACTIVATE'"
            type="primary"
            >激活</el-button
          >
          <el-button
            v-auth="'ACCOUNT:AGENT:AGENTS:BASE/JBXX/CLOSED'"
            @click="setStatus('CLOSED')"
            v-if="detail.checkStatus == 'PASS'"
            class="returnBt"
            :class="detail.activateStatus == 'CLOSED' ? 'active' : ''"
            :disabled="detail.activateStatus == 'CLOSED'"
            type="primary"
            >关闭</el-button
          >
          <el-button
            v-auth="'ACCOUNT:AGENT:AGENTS:BASE/JBXX/FROZEN'"
            @click="setStatus('FROZEN')"
            v-if="detail.checkStatus == 'PASS'"
            class="returnBt"
            :class="detail.activateStatus == 'FROZEN' ? 'active' : ''"
            :disabled="detail.activateStatus == 'FROZEN'"
            type="primary"
            >冻结</el-button
          >
        </div>
        <div class="cover_view" v-if="showImgUrl !== ''" @click="showImgUrl = ''">
          <a :href="showImgUrl" target="_blank">
            <img :src="showImgUrl" alt="加载失败" />
          </a>
        </div>
        <div
          class="cover_view"
          v-if="showImgUrls.length > 0"
          @click="showImgUrls = []"
        >
          <i
            v-show="showImgUrlIndex > 0"
            class="el-icon-arrow-left left"
            @click.stop="showImgUrlIndex--"
          ></i>
          <i
            v-show="showImgUrlIndex < showImgUrls.length - 1"
            class="el-icon-arrow-right right"
            @click.stop="showImgUrlIndex++"
          ></i>
          <a :href="showImgUrls[showImgUrlIndex].url" target="_blank">
            <img :src="showImgUrls[showImgUrlIndex].url" alt="加载失败" />
          </a>
        </div>
        <div class="cover_view"  v-if="DLSImgData !== ''" @click="DLSImgData = ''">
          <el-carousel :interval="5000" arrow="always" style="width:100vw;height:100vh;">
            <el-carousel-item v-for="(v,i) in DLSImgData" :key="i">
                <img :src="v.url" alt="" style="width: auto; height: auto;display: block;margin: auto;max-width: 100%;">
            </el-carousel-item>
          </el-carousel>
        </div>
    </div>
  </div>
</template>
<script>
import {
  getAgentsDetail,
  auditAgents,
  setAgentsStatus,
  resend,
} from "@/api/user/agentsManager.js";
export default {
  data() {
    return {
      detail: {},
      checkStatus: {
        WAIT: "待审核",
        PASS: "已通过",
        REJECT: "已驳回",
      },
      activateStatus: {
        NOTACTIVATE: "未激活",
        ACTIVATE: "已激活",
        FROZEN: "已冻结",
        CLOSED: "已关闭",
      },
      showImgUrl: "",
      showImgUrls: [], //查看多个图片
      showImgUrlIndex: 0, //查看多个图片切换
      DLSImgData:"",
    };
  },
  created() {
    this.getDetail();
  },
  methods: {
    // 获取详情
    getDetail() {
      getAgentsDetail(this.$route.query.id).then((res) => {
        if (res) {
          this.detail = res.resultData.agentDetail;
        }
      });
    },
    // 浏览单个图片
    showImg(val) {
      this.showImgUrl = val;
    },
    // 浏览多个图片
    showImgs(val) {
      this.showImgUrls = val ? JSON.parse(val) : [];
      this.showImgUrlIndex = 0;
    },
    agentProtocolBtn(val){
      console.log(val,"0000000000000")
      this.DLSImgData = JSON.parse(val);
    },
    // 审核商户
    audit(val) {
      auditAgents(this.$route.query.id, {
        checkStatus: val,
      }).then((res) => {
        if (res) {
          this.$message.success("审核成功");
          this.getDetail();
        }
      });
    },
    // 激活/冻结商户
    setStatus(val) {
      setAgentsStatus(this.$route.query.id, {
        activateStatus: val,
      }).then((res) => {
        if (res) {
          switch (val) {
            case "ACTIVATE":
              this.$message.success("激活成功");
              break;
            case "FROZEN":
              this.$message.success("冻结成功");
              break;
            case "CLOSED":
              this.$message.success("关闭成功");
              break;
          }
          this.getDetail();
        }
      });
    },
    // 重发邮件
    reEmail() {
      resend(this.$route.query.id).then((res) => {
        if (res.resultStatus) {
          this.$message.success("重发邮件请求成功");
        }
      });
    },
    // 跳转页面
    toUrl(name, query) {
      this.$router.replace({
        name: name,
        query: {
          ...query,
        },
      });
      // this.$router.push({
      // 	name: name,
      // 	query: {
      // 		...query
      // 	}
      // })
    },
  },
};
</script>
<style scoped>
@import "../../../../assets/css/desc.css";
@import "../../../../assets/css/detail.css";
.el-carousel__item{
  width: 100vw;
  height: 100vh;
}
/deep/ .el-carousel__container {
    position: relative;
    height: 100vh !important;
}

.next {
  width: 120px;
  height: 32px;
  padding: 0;
  background: rgba(72, 184, 182, 1);
  border-radius: 3px;
  line-height: 10px;
  margin-left: 10px;
  font-size: 12px;
}

.back {
  width: 120px;
  line-height: 10px;
  height: 32px;
  padding: 0;
  background: rgba(255, 255, 255, 1);
  border: 1px solid rgba(220, 224, 230, 1);
  border-radius: 3px;
  color: #333333;
  font-size: 12px;
}

.btn {
  width: 100%;
  text-align: center;
  height: 33px;
}
.title {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(51, 51, 51, 1);
  line-height: 40px;
}


.status_desc {
  height: 32px;
  margin: 0 10px 20px 15px;
  padding: 10px 30px 10px 35px;
  background-color: #f4f5f6;
  font-size: 16px;
  line-height: 32px;
  color: #999999;
}

.status_desc .status {
  margin-left: 56px;
  color: #333333;
}

.status_desc .overBt {
  float: right;
  width: 60px;
  height: 32px;
  margin-left: 10px;
  padding: 0;
  background-color: #aaaaaa;
  border-color: #aaaaaa;
  font-size: 12px;
  color: #ffffff;
  border-radius: 3px;
}

.status_desc .overBt.active {
  background: rgba(72, 184, 182, 1);
  border-color: #5aafab;
}

.status_desc .returnBt {
  float: right;
  width: 60px;
  height: 32px;
  margin-left: 10px;
  padding: 0;
  background: rgba(255, 255, 255, 1);
  border: 1px solid rgba(215, 218, 226, 1);
  border-radius: 3px;
  font-size: 12px;
  color: #333333;
}
.status_desc .returnBt.active {
  background-color: #aaaaaa;
  border-color: #aaaaaa;
  font-size: 12px;
  color: #ffffff;
}
.cover_view {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
}

.cover_view img {
  position: absolute;
  min-height: 200px;
  min-width: 200px;
  max-height: 100%;
  max-width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
}

.cover_view .left {
  position: absolute;
  top: 50%;
  left: 50px;
  transform: translateY(-50%);
  color: #ffffff;
  font-size: 26px;
}

.cover_view .right {
  position: absolute;
  top: 50%;
  right: 50px;
  transform: translateY(-50%);
  color: #ffffff;
  font-size: 26px;
}
</style>
